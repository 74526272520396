@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Familjen+Grotesk:wght@400;700&display=swap');

body {
  background-color: white;
}

.slick-slide > div{
  margin: 0 15px;
}


